

@media (max-width: 600px) {
   

    .options-detail div
       {
        width: 100%  !important;
        margin-bottom: 20px;
        
    }

    .recomended-weekend .item-categorie{
        width: 83%  !important;
        margin-bottom: 20px;
    }


    .btn-nav{
        top: 30% !important;
    }

    
}

@media (max-width: 500px) {
    


    .btn-nav{
        top: 20% !important;
    }

    
}