
@media (max-width: 670px) {
 .digital-menu{
    display: none;
    }


 
    .ordernaow {
        width: 130px !important;
    }
    .ordernaow span{
        font-size: 12px !important;
    }

}


@media (max-width: 915px) {
    .nav-menu-bar, .scroll-view{
        padding-inline: 90px !important;
    }

    .nav-menu-bar, .scroll-view{
        padding-inline: 90px !important;
    }

    .content-footer{
         padding-inline: 90px !important;
    }
}

@media (max-width: 980px) {
      .content-footer-ordernow a{
        width: 230px !important;
      }
}



@media (max-width: 600px) {

     .nav-menu-bar, .scroll-view{
        padding-inline: 20px !important;
    }

    .ordernaow{
        display: none !important;
    }


    .nav-menu-bar{
        border-radius: 0px !important;
    }
    .contentChild{
        padding: 0px !important;
    }

    .content-footer-ordernow{
        flex-direction: column;
        align-items: center;
    }

    .content-footer-ordernow  a{
        margin-bottom: 10px;
    }
}


@media (max-width: 500px) {
    .content-home{
        padding: 0 25px !important;
    }

    .content-categotie-list{
        justify-content: space-between;
    }

    
}



@media (max-width: 1220px) {
    .content-footer-ordernow{
       width: 80% !important;
    }


    
}



 
